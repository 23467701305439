const Routes = {
  ProjectChat: (projectticketid, projectid) => `/project_chat/${projectticketid}/${projectid}`,
  dashboard: (projectid) => `/dashboard/${projectid}`,
  dashboard1: "/dashboard",
  ShareEmployee: "/share_emp",
  AddTicket: "/add_ticket",
  login: "/login",
  Client: "/client",
  Projects: "/projects",
  Employee: "/employee",
  Profile: (id) => `/profile/${id}`,
};

export default Routes;
