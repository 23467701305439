import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { startLoading, stopLoading } from "Routes/slice";
import { getEmployeeById, getClientById } from "api/employee";
import "./style.scss";

const MainHeader = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const navigate = useNavigate(); // Initialize navigate
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleMenuClick = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleProfileClick = () => {
    if (userData && userData.id) {
      if (userData.role === "EMPLOYEE" || userData.role === "CLIENT") {
        // Navigate to the profile page with the user's id
        navigate(`/profile/${userData.id}`);
      }
    }
  };

  return (
    <div className="main_header">
      <div className="menus">
        <div className="account-menu">
          <div className="menulist" onClick={handleMenuClick}>
            <i className="riot-icon riot-icon-expencess" />
            {userData && userData.firstname ? userData.firstname : "Account"}
          </div>

          {isDropdownVisible && (
            <div className="dropdown-menu">
              {userData && userData.role !== "CLIENT" && userData.role !== "EMPLOYEE" && (
                <>
                  <Link to="/dashboard">Tickets</Link>
                  <Link to="/projects">Projects</Link>
                  <Link to="/client">Client</Link>
                  <Link to="/employee">User</Link>
                </>
              )}
              <div onClick={handleProfileClick} style={{ padding: "10px" }}>
                Profile
              </div>
              <Link to="/login">Logout</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
