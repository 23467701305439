import React, { useState, useEffect } from "react";
import MainHeader from "component/MainHeader";
import { Select, Input, Button, DatePicker, message } from "antd";
import { useNavigate } from "react-router-dom";
import { dateFormat } from "constant";
import moment from 'moment';
import {
    getAllEmployees,
    addUserData,
    getAssignedEmployee,
    AssignEmployee,
    ticketStatusApi,
    ticketDuedateApi,
    getProjectSummary
} from "api/projectTicket";
import AppRoutes from "constant/appRoutes";
import { startLoading, stopLoading } from "Routes/slice";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { ArrowRightOutlined } from "@ant-design/icons";
import "styles/main_table.scss";
import "./style.scss";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getAllProjects } from "api/projects";
import { getSummary } from "api/projectTicket";
function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const MobileDashboard = () => {
    const navigate = useNavigate();
    const { projectid } = useParams();
    const options = [];
    const [projectTicketList, setProjectTicketList] = useState([]);
    const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [tasks, setTasks] = useState(projectTicketList);
    const [newTicket, setnewTicket] = useState("");
    const [errorMessage, setErrorMessage] = useState('');
    const [projectSummary, setProjectSummary] = useState(null);
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const [projectList, setProjectList] = useState([]);
    const [summary, setSummary] = useState([]);
    useEffect(() => {
        fetchProjectData();
        fetchSummary();
    }, []);
    const fetchProjectData = async () => {
        try {
            dispatch(startLoading());
            const response = await getAllProjects();
            if (Array.isArray(response)) {
                setProjectList(response);
            } else {
                console.error("Expected an array but received:", response);
                addToast("Unexpected data format", {
                    appearance: "error",
                    autoDismiss: true,
                });
            }
        } catch (error) {
            console.log(error);
            addToast(error.message || "Error fetching employees", {
                appearance: "error",
                autoDismiss: true,
            });
        } finally {
            dispatch(stopLoading());
        }
    };
    const fetchSummary = async () => {
        try {
            dispatch(startLoading());
            const response = await getSummary();
            setSummary(response);
        } catch (error) {
            console.log(error);
            addToast("Failed to fetch project summary", {
                appearance: "error",
                autoDismiss: true,
            });
        } finally {
            dispatch(stopLoading());
        }
    };

    useEffect(() => {
        if (projectid) {
            fetchData(projectid);
            fetchEmployeeData(projectid);
            fetchProjectSummary(projectid);
        }
    }, [projectid]);
    const fetchProjectSummary = async (projectid) => {
        try {
            dispatch(startLoading());
            const response = await getProjectSummary(projectid);
            setProjectSummary(response.body);
        } catch (error) {
            console.log(error);
            addToast("Failed to fetch project summary", {
                appearance: "error",
                autoDismiss: true,
            });
        } finally {
            dispatch(stopLoading());
        }
    };
    const fetchData = async (projectid) => {
        try {
            dispatch(startLoading());
            const response = await getAllEmployees(projectid);
            setProjectTicketList(response);
        } catch (error) {
            console.log(error);
            addToast(error, {
                appearance: "error",
                autoDismiss: true,
            });
        } finally {
            dispatch(stopLoading());
        }
    };
    const fetchEmployeeData = async (projectid) => {
        try {
            dispatch(startLoading());
            const response = await getAssignedEmployee({ projectid: projectid });
            setEmployeeList(response);

            for (let i = 1; i <= response.length; i++) {
                options.push(
                    response.forEach((employee) =>
                        options.push({
                            value: employee.employeeid,
                            label: employee.employeeid,
                        })
                    )
                );
            }
        } catch (error) {
            console.log(error);
            addToast(error, {
                appearance: "error",
                autoDismiss: true,
            });
        } finally {
            dispatch(stopLoading());
        }
    };
    const handleStatusChange = async (newStatus, value) => {
        try {
            const body = {
                status: newStatus,
                projectticketid: value.projectticketid,
            };
            dispatch(startLoading());
            const response = await ticketStatusApi(body);
        } catch (error) {
            console.log(error);
            addToast(error, {
                appearance: "error",
                autoDismiss: true,
            });
        } finally {
            fetchData(projectid);
        }
    };
    const createNewTicket = async () => {
        if (!newTicket.trim()) {
            message.error('Your ticket is empty. Please fill in your ticket.');
            return;
        }
        const body = {
            projectid: projectid,
            ticketname: newTicket,
        };
        try {
            dispatch(startLoading());
            const response = await addUserData(body);
            if (response) {
                addToast("success ticket added successfully", {
                    appearance: "success",
                    autoDismiss: true,
                });
            } else {
                addToast("ticket add failed", {
                    appearance: "error",
                    autoDismiss: true,
                });

            }
        } catch (error) {
            console.log(error);
            addToast(error, {
                appearance: "error",
                autoDismiss: true,
            });
        } finally {
            fetchData(projectid);
        }
        console.log("newTicket", newTicket);
        setnewTicket("");
    };
    const handleEmployeeSelect = async (value, record) => {
        setSelectedEmployeeIds(value);
        console.log("Selected Employee IDs:", value);
        const body = {
            employeeIds: value,
            ticketId: record.projectticketid,
        };
        try {
            dispatch(startLoading());
            const response = await AssignEmployee(body);
        } catch (error) {
            console.log(error);
            addToast(error, {
                appearance: "error",
                autoDismiss: true,
            });
        } finally {
            fetchData(projectid);
        }
        setnewTicket("");
    };
    const handleDateChange = async (date, record) => {

        try {
            const ffdate = date && !moment.isMoment(date) ? moment(date, dateFormat) : date;
            const body = {
                duedate: ffdate ? ffdate.format(dateFormat) : null,
                projectticketid: record.projectticketid,
            };
            dispatch(startLoading());
            const response = await ticketDuedateApi(body);
        } catch (error) {
            console.log(error);
            addToast(error, {
                appearance: "error",
                autoDismiss: true,
            });
        } finally {
            fetchData(projectid);
        }
    };
    const disabledDate = (current) => {
        return current && current < moment().startOf('day');
    };
    const projects = [
        ...projectList.map((project) =>
            getItem(
                <div className="sidenav-projects">
                    <Link to={AppRoutes.dashboard(project.projectid)}>
                        <div>{project.projectname}</div>
                        <div className="project-companyname">{project.projectcompanyname}</div>
                        {project.msgCount && <div className="msg_count">{project.msgCount}</div>}
                        <div className={`project_${project.status}`}></div>
                    </Link>
                </div>
            )
        ),
    ];
    return (
        <div className="bodytable">
            <div className="page-name">
                <div className="page-name-title">
                    <h1> {projectSummary ? `${projectSummary.projectName} - ${projectSummary.projectCompanyName}` : "JOLIASS"}</h1>
                </div>
                <MainHeader />
            </div>
            {projectTicketList && projectTicketList.length > 0 ? (
                <div className="body_content">
                    {projectTicketList.map((e, index) => (
                        <div key={e.id} className="ticket_item">
                            {" "}
                            <div className="top_date">
                                <div className="posted_date">
                                    <p>{e.projectticketcreatedDtTime}</p>
                                </div>
                                <div className="selected_emp">
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        value={e.assignedEmployeeModel?.map((e) => e.employeeid) || []}
                                        placeholder="Please select Employee"
                                        options={employeeList.length > 0 && employeeList.map((emp) => ({
                                            value: emp.employeeid,
                                            label: `${emp.empname} (${emp.empcode})`,
                                        }))}
                                        onChange={(value) => handleEmployeeSelect(value, e)}
                                    />
                                </div>
                                <div className="right-item">
                                    <p>Due :
                                        <DatePicker
                                            value={e.duedate ? moment(e.duedate, dateFormat) : null}
                                            format={dateFormat}
                                            disabledDate={disabledDate}
                                            onChange={(value) => handleDateChange(value, e)}
                                        />
                                    </p>
                                </div>
                            </div>
                            <div className="info_content">
                                <div className="emp_name">
                                    <p>{e.username}</p> {e.entryCount && e.entryCount > 0 && <span className="entry_count">{e.entryCount}</span>}
                                </div>
                                <div className="task_description">
                                    <Link to={AppRoutes.ProjectChat(e.projectticketid, e.projectid)}>
                                        <p>{e.ticketname}...</p>
                                    </Link>
                                </div>
                                <div className="task_status">
                                    <Select
                                        className="task_date"
                                        showSearch
                                        placeholder="Select a Status"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? "")
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        value={e.status}
                                        onChange={(value) => handleStatusChange(value, e)}
                                        options={[
                                            { value: "pending", label: "Pending" },
                                            { value: "ongoing", label: "Ongoing" },
                                            { value: "completed", label: "Completed" },
                                            { value: "verified", label: "Verified" },
                                            { value: "reopen", label: "Reopen" },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="create_new">
                        <div className="create_new_task">
                            <Input
                                value={newTicket}
                                onChange={(e) => setnewTicket(e.target.value)}
                                placeholder="Create New Job"
                                suffix={
                                    <ArrowRightOutlined
                                        onClick={createNewTicket}
                                        style={{ cursor: "pointer", fontSize: '24px' }}
                                    />
                                }
                                style={{ height: "50px", fontSize: "16px", borderRadius: '10px' }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        createNewTicket();
                                    }
                                }}
                            />
                        </div>
                        {errorMessage && (
                            <div className="error-message" style={{ color: 'red', marginTop: '10px' }}>
                                {errorMessage}
                            </div>
                        )}
                    </div>
                </div>
            ) : projectid ? (
                <div>
                    <div className="select_project">No tickets found</div>
                    <div className="create_new">
                        <div className="create_new_task">
                            <Input
                                value={newTicket}
                                onChange={(e) => setnewTicket(e.target.value)}
                                placeholder="Create New Job"
                                suffix={
                                    <ArrowRightOutlined
                                        onClick={createNewTicket}
                                        style={{ cursor: "pointer", fontSize: '24px' }}
                                    />
                                }
                                style={{ height: "50px", fontSize: "16px", borderRadius: '10px' }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        createNewTicket();
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container">
                    {summary.map((project) => (
                        <div key={project.projectId} className="project-card">
                            <Link to={AppRoutes.dashboard(project.projectid)}>
                            <h2>{project.projectName}</h2>
                            <p>{project.companyName}</p>
                            <div className="project-status">
                                <div className="pending">Pending {project.pendingTicketCount}</div>
                                <div className="ongoing">Ongoing {project.ongoingTicketCount}</div>
                                <div className="completed">Completed {project.completedTicketCount}</div>
                                <div className="project-icon">{project.totalTickets}</div>
                            </div>
                            </Link>
                        </div>
                    ))}
                </div>

            )}
        </div>
    );
};
export default MobileDashboard;
