import Service from "utils/HttpClient";
import { config } from "constant/config";

export const getAllEmployees = async (projectid) => {
  return await Service.getApi(config.API_URL, "/tickets/project/"+projectid);
};
export const getProjectSummary = async (projectid) => {
  return await Service.getApi(config.API_URL, `/tickets/${projectid}/summary`);
};
export const getSummary = async () => {
  return await Service.getApi(config.API_URL, `/tickets/summary`);
};

export const getAssignedEmployee = async (body) => {
  return await Service.postApi(config.API_URL, "/projects/getemployeeproject",body);
};

export const addUserData = async (body) =>{
 return await Service.postApi(config.API_URL, "/tickets/create", body);
}

export const AssignEmployee = async (body) =>{
  return await Service.postApi(config.API_URL, "/tickets/assign-employees", body);
 }
export const ticketStatusApi = async (body) =>{
  return await Service.postApi(config.API_URL, "/tickets/updatestatus", body);
 }
 export const ticketDuedateApi = async (body) =>{
   return await Service.postApi(config.API_URL, "/tickets/updateduedate", body);
  }
