import { lazy, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Spin from "antd/lib/spin";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { updateAlertData } from "Routes/slice";
import Profile from "pages/Profile";
import MobileDashboard from "pages/mobile_dashboard";

const Home = lazy(() => import("pages/home"));
const Login = lazy(() => import("pages/login"));
const ProjectChat = lazy(() => import("pages/ProjectChat"));


export const AppRoutes = () => {
  const appLoading = useSelector((state) => state.app.appLoading);
  const appAlertData = useSelector((state) => state.app.alertData);

  const { addToast } = useToasts();
  const dispatch = useDispatch();

  useEffect(() => {
    if (appAlertData.message) {
      addToast(appAlertData.message, {
        appearance: appAlertData.type,
        autoDismiss: true,
      });
      setTimeout(() => {
        dispatch(updateAlertData({}));
      }, 3000);
    }
  }, [appAlertData, dispatch, addToast]);

  return (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      spinning={appLoading}
      tip="Loading..."
    >
      <Routes>
        <Route path="/project_chat/:projectticketid" element={<ProjectChat />}/>
        {/* <Route path="/dashboard/:projectid" element={<Dashboard />} /> */}
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        <Route key={"login"} path="/" element={<Login />} />
        <Route key={"login"} path="/login" element={<Login />} />
        {/* <Route key={"default"} path="*" element={<Home />} /> */}
        <Route key={"default"} path="*" element={<Home />} />
        <Route key={"profile"} path="/profile/:id" element={<Profile />} />
        <Route key={"mobiledashboard"} path="/mobiledashboard" element={<MobileDashboard />} />
      </Routes>
    </Spin>
  );
};
