import Service from "utils/HttpClient";
import { config } from "constant/config";

export const getAllProjects = async () => {
  return await Service.getApi(config.API_URL, "/projects/getAllProjects");
};

export const getAllClient = async () => {
  return await Service.getApi(config.API_URL, "/clients/getAllClients");
};


export const addUserData = async (body) =>{
 return await Service.postApi(config.API_URL, "/projects/createProject", body);
}

export const editserData = async (body) =>{
  return await Service.postApi(config.API_URL, "/projects/updateProject", body);
 }
export const deleteApi = async (body) =>{
  return await Service.postApi(config.API_URL, "/projects/deleteProject", body);
 }
 export const getAllUsers = async () => {
   return await Service.getApi(config.API_URL, "/employees/getAllEmployees");
 };
