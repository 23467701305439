import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getEmployeeById, getClientById } from "api/employee";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "Routes/slice";
import { useToasts } from "react-toast-notifications";
import MainHeader from "component/MainHeader";

const Profile = () => {
    const { id } = useParams(); // Get the user ID from URL params
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const [profileData, setProfileData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(startLoading());

                // Assuming you can check user role in localStorage
                const userData = JSON.parse(localStorage.getItem("userData"));

                if (userData && userData.role === "EMPLOYEE") {
                    const response = await getEmployeeById(id);
                    setProfileData(response);
                } else if (userData && userData.role === "CLIENT") {
                    const response = await getClientById(id);
                    setProfileData(response);
                }
            } catch (error) {
                console.error(error);
                addToast("Error fetching profile data", {
                    appearance: "error",
                    autoDismiss: true,
                });
            } finally {
                dispatch(stopLoading());
            }
        };

        fetchData();
    }, [id, dispatch, addToast]);

    if (!profileData) return <p>Loading...</p>;

    return (
        <div className="bodytable">
            <div className="page-name">
                <div className="page-name-title">
                    <h1>JOLIASS</h1>
                </div>
                <MainHeader />
            </div>
            <div className="profile-page">
                {profileData.role === "EMPLOYEE" ? (
                    <div className="employee-details">
                        {/* Profile Header */}
                        <div className="profile-header">
                            <img
                                src={profileData.imageURl}
                                alt={profileData.empname}
                                className="profile-image"
                            />
                            <h2>{profileData.empname}</h2>
                            <p>{profileData.designation}</p>
                        </div>
                        {/* Profile Info */}
                        <div className="profile-info">
                            <p><strong>Employee Code:</strong> {profileData.empcode}</p>
                            <p><strong>Email:</strong> {profileData.email}</p>
                            <p><strong>Mobile:</strong> {profileData.mobile}</p>
                            <p><strong>Date of Joining:</strong> {profileData.dateofjoining}</p>
                        </div>
                    </div>
                ) : (
                    <div className="client-details">
                        <div className="profile-header">
                            <img
                                src={profileData.imageURl}
                                alt={profileData.clientname}
                                className="profile-image"
                            />
                            <h2>{profileData.clientname}</h2>
                        </div>
                        <div className="profile-info">
                            <p><strong>Company Name:</strong> {profileData.companyname}</p>
                            <p><strong>Contact Person:</strong> {profileData.contactperson}</p>
                            <p><strong>Email:</strong> {profileData.email}</p>
                            <p><strong>Mobile:</strong> {profileData.contactnumber}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Profile;
